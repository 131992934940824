import HttpRequest from './http-request'
import { getAuthToken } from '../utils/auth.utils'

class MemberRanksProvider extends HttpRequest {
  getItems (query) {
    this.setHeader(getAuthToken())
    return this.get('/member-ranks', query)
  }

  getItemByName (name) {
    this.setHeader(getAuthToken())
    return this.get(`/member-ranks/${name}/rank`)
  }

  getItemById (id) {
    this.setHeader(getAuthToken())
    return this.get(`/member-ranks/${id}`)
  }

  create (payload) {
    this.setHeader(getAuthToken())
    return this.post('/member-ranks', payload)
  }

  updateItemById (payload) {
    this.setHeader(getAuthToken())
    return this.patch(`/member-ranks/${payload.id}`, payload)
  }

  deleteItemById (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/member-ranks/${id}`)
  }
}

export default MemberRanksProvider
