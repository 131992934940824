import HttpRequest from './http-request'
import { getAuthToken } from '../utils/auth.utils'

class PrivilegesProvider extends HttpRequest {
  getItems (query) {
    this.setHeader(getAuthToken())
    return this.get('/privileges', query)
  }

  getItemById (id) {
    this.setHeader(getAuthToken())
    return this.get(`/privileges/${id}`)
  }

  create (payload) {
    this.setHeader(getAuthToken())
    return this.post('/privileges', payload)
  }

  randomCode () {
    this.setHeader(getAuthToken())
    return this.post('/privileges/random-code')
  }

  updateItemById (payload) {
    this.setHeader(getAuthToken())
    return this.patch(`/privileges/${payload.id}`, payload)
  }

  deleteItemById (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/privileges/${id}`)
  }
}

export default PrivilegesProvider
